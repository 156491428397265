import React from 'react';
import { bool, node } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  FieldCheckbox,
  FieldCountrySelect,
  FieldCurrencySelect,
  FieldSelect,
} from '../../../components';
import { useConfiguration } from '../../../context/configurationContext';
import { isMarketplaceRespare, isMarketplaceSecondLife } from '../../../util/marketplace';
import { ACCOUNT_TYPES_OPTIONS, USER_TYPES_BY_ACCOUNT_OPTIONS } from '../../../util/user';
import { ACCOUNT_TYPE, USER_TYPE } from '../../../constants/user';

import css from './SignupForm.module.css';
import { useSelector } from 'react-redux';

const SignupFormComponent = ({ intl, ...rest }) => {
  const { queryCompaniesInProgress, queryCompaniesError, companies } = useSelector(
    state => state.company
  );

  const config = useConfiguration();

  return (
    <FinalForm
      {...rest}
      validate={values => {
        const { password, confirmPassword } = values;

        if (password !== confirmPassword) {
          return { confirmPassword: intl.formatMessage({ id: 'SignupForm.passwordMismatch' }) };
        }
      }}
      mutators={{ ...arrayMutators }}
      destroyOnUnregister
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          form,
          formId,
          handleSubmit,
          inProgress,
          invalid,
          termsAndConditions,
          values,
        } = fieldRenderProps;

        // email
        const emailRequired = validators.required(
          intl.formatMessage({ id: 'General.emailRequired' })
        );
        const emailValid = validators.emailFormatValid(
          intl.formatMessage({ id: 'General.emailInvalid' })
        );

        // companyName

        const companyNameRequired = validators.required(
          intl.formatMessage({ id: 'SignupForm.companyNameRequired' })
        );

        // password
        const passwordRequiredMessage = intl.formatMessage({
          id: 'SignupForm.passwordRequired',
        });
        const passwordMinLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooShort',
          },
          {
            minLength: validators.PASSWORD_MIN_LENGTH,
          }
        );
        const passwordMaxLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooLong',
          },
          {
            maxLength: validators.PASSWORD_MAX_LENGTH,
          }
        );
        const passwordMinLength = validators.minLength(
          passwordMinLengthMessage,
          validators.PASSWORD_MIN_LENGTH
        );
        const passwordMaxLength = validators.maxLength(
          passwordMaxLengthMessage,
          validators.PASSWORD_MAX_LENGTH
        );
        const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
        const passwordValidators = validators.composeValidators(
          passwordRequired,
          passwordMinLength,
          passwordMaxLength
        );

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = invalid || submitInProgress || queryCompaniesInProgress;

        const accountRoles = USER_TYPES_BY_ACCOUNT_OPTIONS[values.accountType];

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div>
              <FieldTextInput
                type="email"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                autoComplete="email"
                label={intl.formatMessage({ id: 'SignupForm.businessEmailLabel' })}
                placeholder={intl.formatMessage({ id: 'SignupForm.businessEmailPlaceholder' })}
                validate={validators.composeValidators(emailRequired, emailValid)}
              />

              {isMarketplaceSecondLife(config) && (
                <>
                  <FieldSelect
                    id={`${formId}.accountType`}
                    name="accountType"
                    className={css.password}
                    label={intl.formatMessage({ id: 'General.userType' })}
                    validate={validators.required(
                      intl.formatMessage({ id: 'General.fieldRequired' })
                    )}
                    onChange={val => {
                      form.change('userType', USER_TYPES_BY_ACCOUNT_OPTIONS[val][0].value);

                      if (val !== ACCOUNT_TYPE.DEALER) form.change('companyName', null);
                    }}
                  >
                    <option disabled value="">
                      {intl.formatMessage({ id: 'General.userTypePlaceholder' })}
                    </option>
                    {ACCOUNT_TYPES_OPTIONS.map(type => {
                      return (
                        <option key={type.value} value={type.value}>
                          {intl.formatMessage({ id: type.labelId })}
                        </option>
                      );
                    })}
                  </FieldSelect>
                  {accountRoles && (
                    <FieldSelect
                      id={`${formId}.userType`}
                      name="userType"
                      className={css.password}
                      label={intl.formatMessage({ id: 'General.role' })}
                      validate={validators.required(
                        intl.formatMessage({ id: 'General.fieldRequired' })
                      )}
                    >
                      <option disabled value="">
                        {intl.formatMessage({ id: 'General.rolePlaceholder' })}
                      </option>
                      {accountRoles.map(type => {
                        return (
                          <option key={type.value} value={type.value}>
                            {intl.formatMessage({ id: type.labelId })}
                          </option>
                        );
                      })}
                    </FieldSelect>
                  )}

                  {values.userType === USER_TYPE.COMPANY_DEALER && companies?.length > 0 && (
                    <FieldSelect
                      id={`${formId}.company`}
                      name="company"
                      className={css.password}
                      label={intl.formatMessage({ id: 'General.company' })}
                      validate={validators.required(
                        intl.formatMessage({ id: 'General.fieldRequired' })
                      )}
                      onChange={companyId => {
                        form.change('companyName', companies.find(c => c._id === companyId).name);
                      }}
                    >
                      <option disabled value="">
                        {intl.formatMessage({ id: 'SignupForm.companySelectPlaceholder' })}
                      </option>
                      {companies.map(company => {
                        return (
                          <option key={company._id} value={company._id}>
                            {company.name}
                          </option>
                        );
                      })}
                    </FieldSelect>
                  )}

                  {queryCompaniesInProgress && (
                    <p>{intl.formatMessage({ id: 'CompaniesPage.loading' })}</p>
                  )}

                  {queryCompaniesError && (
                    <p className={css.error}>
                      {intl.formatMessage({ id: 'CompaniesPage.fetchError' })}
                    </p>
                  )}
                </>
              )}

              <div className={css.name}>
                <FieldTextInput
                  className={css.firstNameRoot}
                  type="text"
                  id={formId ? `${formId}.fname` : 'fname'}
                  name="fname"
                  autoComplete="given-name"
                  label={intl.formatMessage({
                    id: 'SignupForm.firstNameLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.firstNamePlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'SignupForm.firstNameRequired',
                    })
                  )}
                />
                <FieldTextInput
                  className={css.lastNameRoot}
                  type="text"
                  id={formId ? `${formId}.lname` : 'lname'}
                  name="lname"
                  autoComplete="family-name"
                  label={intl.formatMessage({
                    id: 'SignupForm.lastNameLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.lastNamePlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'SignupForm.lastNameRequired',
                    })
                  )}
                />
              </div>
              {isMarketplaceRespare(config) && (
                <FieldTextInput
                  className={css.password}
                  type="text"
                  id={formId ? `${formId}.companyName` : 'companyName'}
                  name="companyName"
                  autoComplete="organization"
                  label={intl.formatMessage({
                    id: 'SignupForm.companyNameLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.companyNamePlaceholder',
                  })}
                  validate={companyNameRequired}
                />
              )}
              {(isMarketplaceRespare(config) || values.accountType === ACCOUNT_TYPE.DEALER) && (
                <FieldTextInput
                  className={css.password}
                  type="text"
                  id={formId ? `${formId}.customerCode` : 'customerCode'}
                  name="customerCode"
                  autoComplete="off"
                  label={intl.formatMessage({
                    id: 'SignupForm.customerCodeLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.customerCodePlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({ id: 'SignupForm.customerCodeRequired' })
                  )}
                  maxLength={10}
                  format={val => (val ? val.trim() : val)}
                />
              )}
              <FieldCountrySelect formAPI={form} />
              <FieldCurrencySelect formAPI={form} />
              <FieldTextInput
                className={css.password}
                type="password"
                id={formId ? `${formId}.password` : 'password'}
                name="password"
                autoComplete="new-password"
                label={intl.formatMessage({
                  id: 'SignupForm.passwordLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.passwordPlaceholder',
                })}
                validate={passwordValidators}
              />
              <FieldTextInput
                className={css.password}
                type="password"
                id={formId ? `${formId}.confirmPassword` : 'confirmPassword'}
                name="confirmPassword"
                autoComplete="new-password"
                label={intl.formatMessage({
                  id: 'SignupForm.confirmPasswordLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.passwordPlaceholder',
                })}
                validate={passwordValidators}
              />
            </div>

            <div className={css.bottomWrapper}>
              <FieldCheckbox
                name="receiveNewsletter"
                id="receiveNewsletter"
                textClassName={css.finePrint}
                label={intl.formatMessage(
                  { id: 'SignupForm.newsletterLabel' },
                  { marketplaceName: config.marketplaceName }
                )}
                value="true"
              />
              {termsAndConditions}
              <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
                <FormattedMessage id="SignupForm.signUp" />
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  );
};

SignupFormComponent.defaultProps = { inProgress: false };

SignupFormComponent.propTypes = {
  inProgress: bool,
  termsAndConditions: node.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
